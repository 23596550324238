/* eslint-disable prettier/prettier */
import jQuery from 'jquery';
window.$ = jQuery; // workaround for https://github.com/parcel-bundler/parcel/issues/333
import 'bootstrap';
import instantsearch from 'instantsearch.js/es';
import {
  searchBox,
  infiniteHits,
  refinementList,
  stats,
  configure,
  analytics,
} from 'instantsearch.js/es/widgets';
import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';
import { SearchClient as TypesenseSearchClient } from 'typesense'; // To get the total number of docs
import stopwords from '../../stopwords.json';
import { auth } from './asgardeoClient';
import { SPAUtils } from '@asgardeo/auth-spa';
import { handleQueryUpdate, savedQuery, currentToken } from './userHistory';

window.auth = auth;

window.logout = function () {
  auth.signOut();
};

window.login = async function () {
  const isAuthenticated = await auth.isAuthenticated();

  if (isAuthenticated) {
    window.location.href = 'index.html';
  } else {
    window.auth.signIn();
  }
};

let user = undefined;

// Popup will display if we click the view button without user
function showToast(message) {
  const toastContainer = document.getElementById('toast-container');

  const toast = document.createElement('div');
  toast.classList.add('toast');

  const messageDiv = document.createElement('div');
  messageDiv.classList.add('message');
  messageDiv.innerText = message;

  const buttonContainer = document.createElement('div');
  buttonContainer.classList.add('button-container');

  const noButton = document.createElement('button');
  noButton.innerText = 'No, just browse';
  noButton.classList.add('toast-no-btn');
  noButton.addEventListener('click', () => {
    toast.classList.remove('show');
    toast.classList.add('hide');
    toast.addEventListener('click', () => {
      document.getElementById('toast-overlay').style.display = 'none';
      toast.remove();
    });
  });

  const loginButton = document.createElement('button');
  loginButton.innerText = 'Ok, login';
  loginButton.classList.add('toast-login-btn');
  loginButton.addEventListener('click', () => {
    document.getElementById('toast-overlay').style.display = 'none';
    toast.remove();
    login();
  });

  buttonContainer.appendChild(noButton);
  buttonContainer.appendChild(loginButton);

  toast.appendChild(messageDiv);
  toast.appendChild(buttonContainer);

  toastContainer.appendChild(toast);

  document.getElementById('toast-overlay').style.display = 'block';

  setTimeout(() => {
    toast.classList.add('show');
  }, 100);
}

window.checkAuthentication = function (event, element) {
  if (!user) {
    event.preventDefault();
    showToast('Please login to view results');
  } else {
    element.setAttribute('target', '_blank');
  }
};

let typesenseApiKey = '';
(async () => {
  let userToken;
  async function getToken() {
    const accessToken = await auth.getAccessToken();
    return accessToken;
  }
  if (SPAUtils.hasAuthSearchParamsInURL()) {
    user = await auth.signIn({ callOnlyOnRedirect: true });
  } else {
    const isAuthenticated = await auth.isAuthenticated();

    if (!isAuthenticated) {
      document.getElementById('loading').style.display = 'none';
      document.getElementById('main-frame').style.display = 'block';
      // document.getElementById('stats').style.display = 'none';
      //typesenseApiKey = process.env.TYPESENSE_RATE_LIMITED_SEARCH_KEY;
      userToken = 'noToken';
    } else {
      const userinfoResponse = await auth
        .getBasicUserInfo()
        .then((userinfoResponse) => userinfoResponse);
      user = userinfoResponse;
    }
  }
  if (user) {
    // document.getElementById('loading').style.display = 'none';
    // document.getElementById('main-frame').style.display = 'block';
    document.getElementById('login-btn').style.display = 'none';
    document.getElementById('see-more-btn').style.display = 'none';

    userToken = await getToken();
    //typesenseApiKey = process.env.TYPESENSE_SEARCH_ONLY_API_KEY;

    // Set welcome message
    const welcomeMessage = document.getElementById('welcome-message');
    if (user.displayName) {
      welcomeMessage.textContent = `Hi, ${user.givenName}!`;
    } else {
      welcomeMessage.textContent = `Hi, ${user.username}!`;
    }

    // If authenticated, render the search interface
  } else {
    let typingTimeout;
    document.getElementById('logout-btn').style.display = 'none';
    typingTimeout = setTimeout(() => {
      // document.getElementById('see-more-btn').style.display = 'block';
    }, 3000);
  }
  document.addEventListener('DOMContentLoaded', function () {
    renderSearch($('#search-type-select').val());
  });

  // Place the rest of your code here

  let TYPESENSE_SERVER_CONFIG = {
    apiKey: typesenseApiKey,
    nodes: [
      {
        host: process.env.TYPESENSE_WEB_SERVER_HOST,
        port: process.env.TYPESENSE_WEB_SERVER_PORT,
        protocol: process.env.TYPESENSE_WEB_SERVER_PROTOCOL,
      },
    ],
    numRetries: 5,
    additionalHeaders: {
      userToken,
    },
    retryIntervalSeconds: 0.1,
    connectionTimeoutSeconds: 5,
  };


  if (process.env[`TYPESENSE_HOST_2`]) {
    TYPESENSE_SERVER_CONFIG.nodes.push({
      host: process.env[`TYPESENSE_HOST_2`],
      port: process.env.TYPESENSE_PORT,
      protocol: process.env.TYPESENSE_PROTOCOL,
    });
  }

  if (process.env[`TYPESENSE_HOST_3`]) {
    TYPESENSE_SERVER_CONFIG.nodes.push({
      host: process.env[`TYPESENSE_HOST_3`],
      port: process.env.TYPESENSE_PORT,
      protocol: process.env.TYPESENSE_PROTOCOL,
    });
  }

  if (process.env[`TYPESENSE_HOST_NEAREST`]) {
    TYPESENSE_SERVER_CONFIG['nearestNode'] = {
      host: process.env[`TYPESENSE_HOST_NEAREST`],
      port: process.env.TYPESENSE_PORT,
      protocol: process.env.TYPESENSE_PROTOCOL,
    };
  }

  const INDEX_NAME = process.env.TYPESENSE_COLLECTION_NAME;

  async function getIndexSize() {
    let typesenseSearchClient = new TypesenseSearchClient(
      TYPESENSE_SERVER_CONFIG
    );
    let results = await typesenseSearchClient
      .collections(INDEX_NAME)
      .documents()
      .search({ q: "*", sort_by: "date:desc" });

    return results['found'];
  }

  let indexSize;

  (async () => {
    indexSize = await getIndexSize();
  })();

  let search;
  let searchTypeExact = false;
  let resultsCount;

  function renderSearch(searchType) {
    if (search) {
      search.dispose();
    }
    clearResultsContainer();

    let queryBy;
    let inFix;

    console.log('searchType', searchType);

    if (searchType === 'semantic') {
      queryBy = 'summary_vec';
      inFix = 'off';
    } else if (searchType === 'keyword') {
      queryBy = 'decision_text';
      inFix = 'off';
    } else if (searchType === 'casenumber') {
      queryBy = 'standard_casenumber';
      inFix = 'always';
    } else {
      queryBy = 'decision_text,summary_vec';
      inFix = 'off';
    }

    const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
      server: TYPESENSE_SERVER_CONFIG,
      // The following parameters are directly passed to Typesense's search API endpoint.
      //  So you can pass any parameters supported by the search endpoint below.
      //  queryBy is required.
      additionalSearchParameters: {
        query_by: queryBy,
        infix: inFix,
        exclude_fields: 'summary_vec',
      },
    });
    const searchClient = typesenseInstantsearchAdapter.searchClient;

    let snippetQuery;
    let timeout = null;
    let isFirstStateChange = true;

    search = instantsearch({
      searchClient,
      indexName: INDEX_NAME,
      routing: true,
      searchFunction(helper) {
        let query = helper.state.query;
        const page = helper.state.page; // Retrieve the current page
        handleQueryUpdate(query); //send the searchbox input field text
        snippetQuery = query;

        // Check if the search type is exact (keyword)
        const searchType = $('#search-type-select').val();

        if (searchType === 'keyword') {
          searchTypeExact = true;
          // Prevent search if query is empty
          if (query === '') {
            query = query;
          } else {
            // Enforce phrase search for exact search type
            if (!query.startsWith('"') && !query.endsWith('"')) {
              query = `"${query}"`;
            }
          }
        } else {
          // if user toggle from exact to other search type and the query start and end with "". we should remove that ""
          // otherwise the query same as original query
          if (query.startsWith('"') && query.endsWith('"') && searchTypeExact) {
            query = query.slice(1, -1);
            snippetQuery = query;
            searchTypeExact = false;
          }
        }

        const performSearch = () => {
          if (
            query !== "" &&
            ["semantic", "hybrid"].includes($().val(searchType))
          ) {
            helper
              .setQueryParameter(
                "typesenseVectorQuery", // <=== Special parameter that only works in typesense-instantsearch-adapter@2.7.0-3 and above
                `summary_vec:([], k:100)`
              )
              .setPage(page)
              .search();
          } else {
            helper
              .setQuery(query)
              .setQueryParameter("typesenseVectorQuery", null)
              .setPage(page)
              .search();
          }
        };

        if (isFirstStateChange) {
          performSearch();
          isFirstStateChange = false;
        } else {
          clearTimeout(timeout);
          timeout = setTimeout(performSearch, 300);
        }
      },
    });
    // "Type in a search term, or click on one of the examples below",
    search.addWidgets([
      searchBox({
        container: "#searchbox",
        showSubmit: false,
        showReset: false,
        placeholder:
          "Type in a search term",
        autofocus: true,
        cssClasses: {
          input: "pl-searchBar-lg",
        },
      }),

      analytics({
        pushFunction(formattedParameters, state, results) {
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('config', process.env.GOOGLE_ANALYTICS_KEY, {
            'page_path': (window.location.pathname + window.location.search).toLowerCase()
          });

          // Send an event with the search query
          if (state.query) {
            // console.log('Sending search event', state.query);
            // console.log('measurement id', process.env.GOOGLE_ANALYTICS_KEY)
            gtag('event', 'search', {
              'event_category': 'engagement',
              'search_term': state.query,
              'value': 1
            });
          }
        },
      }),

      stats({
        container: "#stats",
        templates: {
          text: ({ nbHits, hasNoResults, hasOneResult, processingTimeMS }) => {
            let statsText = "";
            if (hasNoResults) {
              statsText = "No results";
              resultsCount = 0;
            } else if (hasOneResult) {
              statsText = "1 result";
              resultsCount = 1;
            } else {
              statsText = `${nbHits.toLocaleString()} results`;
              resultsCount = 1;
            }
            return `${statsText} found ${indexSize
              ? ` - Searched ${indexSize.toLocaleString()} decisions`
              : ""
              } in ${processingTimeMS}ms.`;
          },
        },
      }),
      infiniteHits({
        container: "#hits",
        cssClasses: {
          list: "list-unstyled grid-container",
          item: "d-flex flex-column search-result-card bg-light-2",
          loadMore: user ? "pl-show-more-rst mx-auto d-block mb-4"
            : "d-none",
        },
        templates: {
          item(hit) {
            const date = new Date(hit.date).toLocaleDateString();
            // Use snippetResult to get the snippet value for decision_text
            const snippetText = hit._snippetResult?.decision_text?.value || '';
            const highlightText = hit._highlightResult.decision_text.value;
            const keywords = hit.keywords || [];
            const legislation = hit.legislation || "";
            const keywords_laws_provisions_terms_ =
              hit.keywords_laws_provisions_terms_ || "";

            // Use keywords_laws_provisions_terms_ as the keyword if it's not empty
            const displayKeywords =
              keywords_laws_provisions_terms_.length > 0
                ? keywords_laws_provisions_terms_
                : keywords.join(', ');

            // Determine if we should display the judge section
            const judges = hit.judge_final || [];
            let judgeText = '';

            if (judges.length > 0 && !(judges.length === 1 && judges[0] === 'NA')) {
              judgeText = `
                <div class="mt-1 pl-parties">
                  <span class="fw-bold">${judges.length > 1 ? 'Judges:' : 'Judge:'}</span> 
                  <span>${judges.join(',&nbsp;&nbsp;')}</span>
                </div>`;
            }

            // Only display 'Parties' section if nameofparties is a non-empty string
            const partiesText =
              hit.nameofparties && hit.nameofparties.trim() !== ''
                ? `
                <div class="mt-1 pl-parties">
                  <span class="fw-bold">
                  ${
                  hit.standard_casenumber.startsWith('sd')
                  ? 'Special determination on:'
                  : hit.standard_casenumber.includes('digest')
                  ? 'Digest:'
                  : 'Parties:'
                }</span>
                  <span>${hit.standard_casenumber.startsWith('sd')
                  ? hit.nameofparties
                  : displayNameOfParties(hit.nameofparties)
                }</span>
                </div>`
                : '';

            return `
            <div class="mb-5">
              <div class="pl-case">
                ${hit.standard_casenumber
              } <span class="pl-date">${date}<span>  <a href=${user && hit.link
              } class="pl-view-link" onclick ="window.checkAuthentication(event, this)">view</a>  
              </div>
              ${partiesText}
              ${!hit.standard_casenumber.includes('digest') 
                ? `
                  ${judgeText}
                  <div class="mt-1 pl-keywords">
                    <span class="fw-bold">Keywords:</span> <span>${displayKeywords}</span>
                  </div>
                  ${legislation 
                    ? `<div class="mt-1 pl-legislation">
                        <span class="fw-bold">Legislation:</span> 
                        <span>${legislation}</span>
                      </div>`
                    : ""
                  }
                ` : ''
              }
              <div class="mt-1 pl-stopwords">
              ${snippetQuery.startsWith('"') && snippetQuery.endsWith('"') || searchType == 'keyword' ?
                decodeHtml(reformatMarkSentences(snippetText, stopwords) || '')
                : decodeHtml(reformatMarkSentences(highlightText, stopwords) || ''
                )}
              </div>
          `;
          },
          empty: `<div class="pl-empty">No decisions found for <q>{{ query }}</q>. Try another search term.</div>`,
        },
        transformItems: (items) => {
          //display the button to see more results if user doesn't exist. It linked  to the login page
          if (!user && resultsCount === 1) {
            document.getElementById('see-more-btn').style.display = 'block';
          } else {
            document.getElementById('see-more-btn').style.display = 'none';
          }
          return items.map((item) => {
            return {
              ...item,
              display_timestamp: (() => {
                const parsedDate = new Date(item.time * 1000);
                return `${parsedDate.toLocaleString()}`;
              })(),
            };
          });
        },
      }),
      refinementList({
        container: "#year-refinement-list",
        attribute: "decision_year",
        searchable: true,
        searchablePlaceholder: "Filter by year",
        showMore: true,
        cssClasses: {
          searchableInput: "pl-searchBar-sm",
          searchableSubmit: "d-none",
          searchableReset: "d-none",
          showMore: "pl-show-more",
          list: "list-unstyled",
          count: "badge rounded-pill pl-filter-count ms-3",
          label: "d-flex align-items-center pl-filter-option mb-1",
          checkbox: "me-2 ms-1",
        },
      }),
      refinementList({
        container: "#month-refinement-list",
        attribute: "decision_month",
        searchable: false,
        searchablePlaceholder: "Filter by month",
        showMore: true,
        cssClasses: {
          searchableInput: "pl-searchBar-sm",
          searchableSubmit: "d-none",
          searchableReset: "d-none",
          showMore: "pl-show-more",
          list: "list-unstyled",
          count: "badge rounded-pill pl-filter-count ms-3",
          label: "d-flex align-items-center pl-filter-option mb-1",
          checkbox: "me-2 ms-1",
        },
      }),
      refinementList({
        container: "#sc-ca-refinement-list",
        attribute: "sc_or_ca",
        searchable: false,
        searchablePlaceholder: "Filter by year",
        showMore: false,
        cssClasses: {
          searchableInput: "pl-searchBar-sm",
          searchableSubmit: "d-none",
          searchableReset: "d-none",
          showMore: "pl-show-more",
          list: "list-unstyled",
          count: "badge rounded-pill pl-filter-count ms-3",
          label: "d-flex align-items-center pl-filter-option mb-1",
          checkbox: "me-2 ms-1",
        },
      }),
      refinementList({
        container: "#report-refinement-list",
        attribute: "report",
        searchable: false,
        searchablePlaceholder: "Filter by report",
        showMore: false,
        cssClasses: {
          searchableInput: "pl-searchBar-sm",
          searchableSubmit: "d-none",
          searchableReset: "d-none",
          showMore: "pl-show-more",
          list: "list-unstyled",
          count: "badge rounded-pill pl-filter-count ms-3",
          label: "d-flex align-items-center pl-filter-option mb-1",
          checkbox: "me-2 ms-1",
        },
      }),
      refinementList({
        container: '#sd-refinement-list',
        attribute: 'action_type',
        searchable: false,
        searchablePlaceholder: 'Filter by action type',
        showMore: false,
        cssClasses: {
          searchableInput: 'pl-searchBar-sm',
          searchableSubmit: 'd-none',
          searchableReset: 'd-none',
          showMore: 'pl-show-more',
          list: 'list-unstyled',
          count: 'badge rounded-pill pl-filter-count ms-3',
          label: 'd-flex align-items-center pl-filter-option mb-1',
          checkbox: 'me-2 ms-1',
        },
      }),
      refinementList({
        container: "#judge-refinement-list",
        attribute: "judge_final",
        searchable: true,
        searchablePlaceholder: "Search judge",
        showMore: true,
        cssClasses: {
          searchableInput: "pl-searchBar-sm",
          searchableSubmit: "d-none",
          searchableReset: "d-none",
          showMore: "pl-show-more",
          list: "list-unstyled",
          count: "badge rounded-pill pl-filter-count ms-3",
          label: "d-flex align-items-center pl-filter-option mb-2",
          checkbox: "me-2 ms-1",
        },
      }),
      configure({
        hitsPerPage: 15,
        //filters: 'decision_year:2024 && decision_month:January',

      }),
    ]);


    search.on("render", function () {

      // Make artist names clickable
      $("#hits .clickable-search-term").on("click", handleSearchTermClick);
    });

    search.start();

    //if user exist, last searched query automatically render.
    $(function () {
      if (user) {
        const $searchBox = $('#searchbox input[type=search]');
        search.helper.clearRefinements();

        $searchBox.val(savedQuery);

        search.helper.setQuery($searchBox.val()).search();
        setTimeout(() => {
          $searchBox.val(savedQuery);
          document.getElementById('loading').style.display = 'none';
          document.getElementById('main-frame').style.display = 'block';
        }, 2000);
      }
    });
  }

  function handleSearchTermClick(event) {
    const $searchBox = $('#searchbox input[type=search]');
    search.helper.clearRefinements();
    $searchBox.val(event.currentTarget.textContent);
    search.helper.setQuery($searchBox.val()).search();
  }

  // Source: https://stackoverflow.com/a/42182294/123545
  function decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }


  function getSentencesWithMark(text) {
    let sentences = text.split('.');
    // console.log("number of sentences:", sentences.length);
    let sentencesWithMark = sentences.filter((sentence) =>
      sentence.includes('<mark>')
    );
    // console.log("number of sentences with mark:", sentencesWithMark.length);
    return sentencesWithMark;
  }

  function removeMarkIfStopword(markSentence, stopwords) {
    let markMatches = markSentence.match(/<mark>(.*?)<\/mark>/g);
    // If there are no matches, return the original sentence
    if (!markMatches) {
      return markSentence;
    }

    let markWords = markMatches.map((word) =>
      word.replace('<mark>', '').replace('</mark>', '')
    );

    // console.log("mark_words:", markWords);
    for (let word of markWords) {
      if (stopwords.includes(word.trim().toLowerCase())) {
        markSentence = markSentence.replace(`<mark>${word}</mark>`, word);
        // console.log("removed:");
      }
    }
    return markSentence;
  }

  function reformatMarkSentences(text, stopwords) {
    let sentencesWithMark = getSentencesWithMark(text);
    // console.log("sentences with mark:", sentencesWithMark);
    let reformattedSentences = sentencesWithMark.map((sentence) =>
      removeMarkIfStopword(sentence, stopwords)
    );

    // console.log("reformatted sentences:", reformattedSentences);
    return getSentencesWithMark(reformattedSentences.join('. '))
      .slice(0, 5)
      .join('. . . ');

  }

      function displayNameOfParties(nameOfParties) {
        let textToDisplay = '';
      
        if (nameOfParties && nameOfParties.length > 0) {
          // If the string is less than or equal to 250 characters, return it as is
          if (nameOfParties.length <= 250) {
            return nameOfParties;
          }
      
          // Search for the first occurrence of 'Vs.' case insensitive
          const vsIndex = nameOfParties.search(/vs./i);
      
          // If 'Vs.' is found, return 50 characters before and after 'Vs.', with 'Vs.' in the middle
          if (vsIndex > 0) {
            const beforeVs = nameOfParties.substring(0, Math.min(vsIndex, 50)).trim(); // First 50 characters before 'Vs.'
            const afterVs = nameOfParties.substring(vsIndex + 3, vsIndex + 53).trim(); // First 50 characters after 'Vs.'
      
            // Construct the output with ellipses around 'Vs.'
            textToDisplay = `${beforeVs} . . . Vs. ${afterVs} . . .`;
          } else {
            // If no 'Vs.' is found, return the first 100 characters
            textToDisplay = nameOfParties.substring(0, 100);
          }
        }
      
        // Remove non-alphabetical characters from the start if present
        if (textToDisplay.match(/^[^a-zA-Z]/)) {
          textToDisplay = textToDisplay.replace(/^[^a-zA-Z]+/, '');
        }
      
        return textToDisplay;
      }  
  

  function clearResultsContainer() {
    $('#hits').empty();
    // const resultsContainer = document.getElementById('hits');
    // resultsContainer.innerHTML = '';
    console.log('results container cleared');
  }

  $(function () {
    const $searchBox = $('#searchbox input[type=search]');

    renderSearch($('#search-type-select').val());

    // Handle example search terms
    $('.clickable-search-term').on('click', handleSearchTermClick);
    $('#search-type-select').on('change', function () {
      const searchType = this.value;
      renderSearch(searchType);
    });

    // Clear refinements, when searching
    $searchBox.on('keydown', (event) => {
      // manually clear the hits

      search.helper.clearRefinements();

      console.log('refinements cleared');

      clearResultsContainer();
    });

    if (!matchMedia('(min-width: 768px)').matches) {
      $searchBox.on('focus, keydown', () => {
        $('html, body').animate(
          {
            scrollTop: $('#searchbox-container').offset().top,
          },
          500
        );
      });
    }
  });

  // go to top button
  var topBtn = $('#top_button');

  $(window).scroll(function () {
    if ($(window).scrollTop() > 300) {
      topBtn.addClass('show');
    } else {
      topBtn.removeClass('show');
    }
  });

  topBtn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '300');
  });

  // dark light theme change
  const modeToggle = document.getElementById('mode_toggle');
  const body = document.body;
  const currentTheme = localStorage.getItem('pl_theme');
  const modeLabel = document.getElementById('mode_label');

  if (currentTheme) {
    if (currentTheme === 'dark') {
      modeToggle.checked = true;
      body.classList.add('dark-mode');
      modeLabel.textContent = 'Light mode';
    }
  }

  modeToggle.addEventListener('change', () => {
    if (modeToggle.checked) {
      body.classList.add('dark-mode');
      localStorage.setItem('pl_theme', 'dark');
      modeLabel.textContent = 'Light mode';
    } else {
      body.classList.remove('dark-mode');
      localStorage.setItem('pl_theme', null);
      modeLabel.textContent = 'Dark mode';
    }
  });

  $('#logo').on('click', () => {
    location.assign('/');
  });
})();

// $("#logo").on("click", () => {
//   location.assign("/");
// })